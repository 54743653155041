import * as React from 'react';
import Image from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import { useGraphQL } from '../hooks/use-graphql';

import {
  Layout,
  SEO,
  Hero,
  Map,
  Contact,
  OurClients,
  SuitsOverlay,
} from '../components';

function AboutPage() {
  const {
    file,
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "about-hero-new-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          title
          shortName
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title="Bases for Poker and Electronic Gaming Machines" />
      <Hero
        hasGradient
        image={file.childImageSharp.fluid}
        heading={`About ${siteMetadata.title}`}
      />
      <SuitsOverlay />
      <OurStory />
      <OurClients />
      <Contact />
      <Map />
    </Layout>
  );
}

function OurStory() {
  const {
    ourStoryImage,
    site: { siteMetadata },
  } = useGraphQL();

  return (
    <article className="relative pt-24 bg-navy">
      <div className="grid w-full gap-8 px-4 py-24 mx-auto max-w-7xl sm:px-6 lg:px-8 lg:grid-cols-2">
        <Image fluid={ourStoryImage.childImageSharp.fluid} className="flex-1" />
        <div className="my-auto">
          <h2 className="mt-6 text-white heading-2 lg:mt-0">Our Story</h2>
          <div className="mt-6 prose text-white">
            <p>
              {siteMetadata.title} provide quality Electronic Gaming Machine
              (EGM) bases, infill benches, and screens to a variety of gaming
              venues around Australia.
            </p>
            <p>
              We work with some of Australia’s premier Hotels and Registered
              Clubs, creating eye-catching and unique displays using the latest
              in computer-aided design and laser-cutting technology. We create
              bases to your exact size and height requirements.
            </p>
            <p>
              Our team manages projects from start to finish, including laying
              out gaming rooms to assist with ergonomics, light levels, themed
              areas, optimise patron traffic, and adhere to local or state
              gaming regulations.
            </p>
            <p>
              {siteMetadata.shortName} prides itself on providing custom
              solutions for your gaming room and machines. Our bases are
              precision manufactured, efficiently designed, installed with
              minimum delay, and are ongoing cost-effective solutions engineered
              to perform for years and years to come.
            </p>
            <p>
              {siteMetadata.shortName} are proudly Australian, use top quality
              materials, and build to Australian specifications.
            </p>
            <p>
              Need advice or support? Want to know more? Talk to us and we’ll
              give you our extensive expertise on all aspects of EGM bases and
              consoles.
            </p>
          </div>
        </div>
      </div>
    </article>
  );
}

export default AboutPage;
